import React from "react";
import { Link } from "gatsby";
import RenderContent from "../../RenderContent";
import "./HighlightedHeading.scss";

export const HighlightedHeading = ({ heading, colour }) => {
  const colourChoice =
    colour &&
    (colour === "red-text-over-white"
      ? " red-text-over-white"
      : " white-text-over-blue");

  return (
    <section className={`highlighted-heading-component${colourChoice}`}>
      <div className="inner">
        <div className="left-side">
          <RenderContent content={heading} />
        </div>
        <div className="right-side"></div>
      </div>
    </section>
  );
};

HighlightedHeading.defaultProps = {
  heading: "<p>This is the heading example</p>",
  color: "red-text-over-white",
};
