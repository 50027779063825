import React from "react";
import { ImagePass } from "../../Image/ImagePass";
import RenderContent from "../../RenderContent";
import "./TopContentAndImage.scss";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";

const TextContent = ({ content }) => {
  return (
    <div className="content-row content-row--text">
      <RenderContent content={content} />
    </div>
  );
};

const ImageContent = ({ mainImage, iconTop, iconBottom }) => {
  return (
    <ParallaxProvider>
      <div className="content-row content-row--image">
        <div className="main-image-wrapper">
          {iconTop && (
            <Parallax
              y={[-30, 30]}
              tagOuter="div"
              className="image-icon image-icon--top"
            >
              <ImagePass src={iconTop} lazy={false} />
            </Parallax>
          )}
          <ImagePass src={mainImage} className="main-image-img" />
          {iconBottom && (
            <Parallax
              y={[-30, 30]}
              tagOuter="div"
              className="image-icon image-icon--bottom"
            >
              <ImagePass src={iconBottom} lazy={false} />
            </Parallax>
          )}
        </div>
      </div>
    </ParallaxProvider>
  );
};

export const TopContentAndImage = ({
  content,
  mainImage,
  iconTop,
  iconBottom,
}) => {
  return (
    <section className="top-content-and-image">
      <div className="top-content-and-image--inner">
        <TextContent content={content} />
        <ImageContent
          mainImage={mainImage}
          iconTop={iconTop}
          iconBottom={iconBottom}
        />
      </div>
    </section>
  );
};

TopContentAndImage.defaultProps = {
  content: "<p>This is the text content example</p>",
  mainImage: "https://via.placeholder.com/430",
  iconTop: "https://via.placeholder.com/150/0000FF/808080",
  iconBottom: "https://via.placeholder.com/150/000000/FFFFFF",
};
