import React from "react";
/* eslint-disable no-console */
import PropTypes from "prop-types";
import { ImagePass } from "../../Image/ImagePass";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ImageSlider.scss";

export const ImageSlider = ({ images }) => {
  const settings = {
    className: "slider",
    dots: false,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="image-slider-component">
      <Slider {...settings}>
        {images &&
          images.map((image, index) => {
            return (
              <div className="slide" key={index}>
                <div className="image-container">
                  <ImagePass src={image?.image} />
                </div>
              </div>
            );
          })}
      </Slider>
    </section>
  );
};

ImageSlider.propTypes = {
  images: PropTypes.array,
};

ImageSlider.defaultProps = {
  images: [
    {
      image: {
        sourceUrl:
          "https://i.picsum.photos/id/866/552/308.jpg?hmac=2WfF0WhmLjTyO4Ij8NUPtuucwuaEdaW_f9bgdhRyoGQ",
      },
    },
    {
      image: {
        sourceUrl:
          "https://i.picsum.photos/id/1053/552/308.jpg?hmac=NDVBgt1DDhmV_xnPH7ABTcdefHMtLLBgyuPJZ0oFKRI",
      },
    },
    {
      image: {
        sourceUrl:
          "https://i.picsum.photos/id/368/552/308.jpg?hmac=dBJEyc1okKjOxyVhJ7wKuTktSuYm7cuLGDFJqb7i19c",
      },
    },
  ],
};
