import React from "react";
import { ImagePass } from "../../Image/ImagePass";
import { Button } from "../../Button";
import RenderContent from "../../RenderContent";
import "./TwoColumnImageAndContent.scss";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";

const ImageContent = ({ data }) => {
  return (
    <ParallaxProvider>
      <div className="content-row content-row--image">
        <div className="main-image-wrapper">
          {data.iconTop && (
            <Parallax
              y={[-30, 30]}
              tagOuter="div"
              className="image-icon image-icon--top"
            >
              <ImagePass src={data.iconTop} lazy={false} />
            </Parallax>
          )}
          <ImagePass src={data.imagePath} className="main-image-img" />
          {data.iconBottom && (
            <Parallax
              y={[-30, 20]}
              tagOuter="div"
              className="image-icon image-icon--bottom"
            >
              <ImagePass src={data.iconBottom} lazy={false} />
            </Parallax>
          )}
        </div>
      </div>
    </ParallaxProvider>
  );
};

const TextContent = ({ data }) => {
  return (
    <div className="content-row content-row--text">
      <RenderContent content={data.content} />
      <div className="cta-button">
        {data.cta ? (
          <Button
            buttonLink={data?.cta?.url}
            buttonText={data?.cta?.title}
            buttonTheme={data?.buttonTheme}
            buttonIcon={data?.buttonIcon}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export const TwoColumnImageAndContent = ({ theme, rows }) => {
  return (
    <div className={`image-and-content image-and-content--${theme}`}>
      <div className="inner">
        {Object.keys(rows).length > 0 &&
          rows?.map((row, index) => {
            switch (row.contentType) {
              case "image":
                return <ImageContent data={row} key={index} />;

              case "text":
                return <TextContent data={row} key={index} />;

              default:
                return <p key={index}>This content type doesnt exist</p>;
            }
          })}
      </div>
    </div>
  );
};

TwoColumnImageAndContent.defaultProps = {
  theme: "yellow",
  rows: [
    {
      contentType: "image",
      imagePath: "https://via.placeholder.com/430",
      iconTop: "https://via.placeholder.com/150/0000FF/808080",
      iconBottom: "https://via.placeholder.com/150/000000/FFFFFF",
      content: null,
      cta: {
        title: "Default link",
        url: "/",
      },
      buttonIcon: "arrow-right",
      buttonTheme: "red",
    },
    {
      contentType: "text",
      imagePath: null,
      iconTop: null,
      iconBottom: null,
      content: "<p>This is the text content example</p>",
    },
  ],
};
