import React from "react";
/* eslint-disable no-console */
import PropTypes from "prop-types";
import RenderContent from "../../RenderContent";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import useSwiperRef from "../../../hooks/useSwiperRef";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";
import "./InfoSlider.scss";

SwiperCore.use([Navigation]);

export const InfoSlider = ({ mainTitle, boldText, infoSlides }) => {
  const [nextEl, nextElRef] = useSwiperRef();
  const [prevEl, prevElRef] = useSwiperRef();

  return (
    <section className="info-slider-component">
      <div className="inner">
        <div className="left-side">
          <div className="main-title">
            <RenderContent content={mainTitle} />
          </div>
          <div className="bold-text">{boldText}</div>
        </div>
        <div className="right-side">
          <Swiper
            className="mySwiper"
            spaceBetween={30}
            slidesPerView={1}
            navigation={{
              prevEl,
              nextEl,
            }}
            breakpoints={{
              900: {
                slidesPerView: 1.4,
                spaceBetween: 10,
              },
              700: {
                slidesPerView: 1.2,
                spaceBetween: 10,
              },
            }}
          >
            {infoSlides &&
              infoSlides?.map((infoSlide, i) => (
                <SwiperSlide key={`slide_${i}`}>
                  <div className="info-slide" key={i}>
                    <div className="info-slide--wrapper">
                      <div className="info-slide--title">
                        {infoSlide.infoTitle}
                      </div>
                      <div className="info-slide--content">
                        <RenderContent content={infoSlide.infoContent} />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
          <div className="nav-helper">
            <button className="swiper-button-prev" ref={prevElRef} />
            <button className="swiper-button-next" ref={nextElRef} />
          </div>
        </div>
      </div>
    </section>
  );
};

InfoSlider.PropTypes = {
  mainTitle: PropTypes.string,
  boldText: PropTypes.string,
  infoSlides: PropTypes.array,
};

InfoSlider.defaultProps = {
  mainTitle: "<p>default main info title in info slider</p>",
  boldText: "Default bold text in info slider",
  infoSlides: [
    {
      infoTitle: "Default info title 1",
      infoContent: "Default info content 1",
    },
    {
      infoTitle: "Default info title 2",
      infoContent: "Default info content 1",
    },
  ],
};
