import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import "../../sass/global/styles.scss";

const Layout = ({ children, location, wordpressUrl, theme }) => (
  <div id="layout">
    <Header location={location} theme={theme} />
    <div className="main">{children}</div>
    <Footer location={location} />
  </div>
);

export default Layout;
