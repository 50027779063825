import React from "react";
import PropTypes from "prop-types";
import { ImagePass } from "../../Image/ImagePass";
import "./ProjectPeople.scss";

export const ProjectPeople = ({ heading, people }) => {
  return (
    <section className="project-people--component">
      <div className="inner">
        <div className="heading">{heading}</div>
        <div className="people--wrapper">
          {people &&
            people?.map((person, index) => (
              <div className="person" key={index}>
                <div className="person--wrapper">
                  <div className="image-wrapper">
                    <ImagePass src={person.image} className="person--image" />
                  </div>
                  <div className="person--name">{person.name}</div>
                  <div className="person--title">{person.title}</div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

ProjectPeople.propTypes = {
  heading: PropTypes.string,
  people: PropTypes.array,
};
