import React, { useState } from "react";
import PropTypes from "prop-types";
import { ImagePass } from "../../Image/ImagePass";
import RenderContent from "../../RenderContent";
import { Button } from "../../Button";
import "./TeamMembers.scss";

export const TeamMembers = ({ teamMembers }) => {
  const [lightboxDisplay, setLightBoxDisplay] = useState(false);
  const [memberToShow, setMemberToShow] = useState("");

  const showMember = (currentMember) => {
    //set memberToShow to be the one that's been clicked on
    setMemberToShow(currentMember);
    //set lightbox visibility to true
    setLightBoxDisplay(true);
  };

  const hideLightBox = () => {
    setLightBoxDisplay(false);
  };

  return (
    <section className="team-members-component">
      <div className="inner">
        {lightboxDisplay && (
          <div id="lightbox" onClick={hideLightBox}>
            <div
              className="team-member--wrapper"
              onClick={() => showMember(memberToShow.image)}
            >
              <div className="team-member--image-wrapper">
                <ImagePass
                  src={memberToShow.image}
                  className="team-member--image"
                />
              </div>
              <div
                className={`team-member--name team-member--name--${memberToShow.theme}`}
              >
                {memberToShow.name}
              </div>
              <div className="team-member--title">{memberToShow.title}</div>
              <div className="team-member--email">
                <a href={`mailto: ${memberToShow.email}`}>
                  {memberToShow.email}
                </a>
              </div>
            </div>
            <div className="bio-wrapper">
              <RenderContent content={memberToShow.bio} />
            </div>
          </div>
        )}
        {teamMembers &&
          teamMembers?.map((teamMember, index) => (
            <div
              className={`team-member${
                lightboxDisplay ? "  lightbox-open" : "  lightbox-closed"
              }`}
              key={index}
            >
              <div
                className="team-member--wrapper"
                onClick={() => showMember(teamMember)}
              >
                <div className="team-member--image-wrapper">
                  <ImagePass
                    src={teamMember.image}
                    className="team-member--image"
                  />
                </div>
                <div
                  className={`team-member--name team-member--name--${teamMember.theme}`}
                >
                  {teamMember.name}
                </div>
                <div className="team-member--title">{teamMember.title}</div>
                <div className="team-member--email">
                  <a href={`mailto: ${teamMember.email}`}>{teamMember.email}</a>
                </div>
                <button
                  className={`button-component button-component--${teamMember.theme}`}
                >
                  View Bio
                </button>
              </div>
            </div>
          ))}
      </div>
    </section>
  );
};

TeamMembers.propTypes = {
  teamMembers: PropTypes.array,
};
