import React from "react";
import { Link } from "gatsby";
import RenderContent from "../../RenderContent";
import "./TwoColumnTextAndCta.scss";
import { Button } from "../../Button";

export const TwoColumnTextAndCta = ({
  boldText,
  content,
  wordpress_id,
  cta,
  buttonTheme,
  buttonIcon,
}) => {
  return (
    <section className="two-col-text-and-cta" id={wordpress_id}>
      <div className="inner">
        <div className="left-side">
          <div className="bold-text">
            <RenderContent content={boldText} />
          </div>
        </div>
        <div className="cta-button">
          {cta ? (
            <Button
              buttonLink={cta.url}
              buttonText={cta.title}
              buttonTheme={buttonTheme}
              buttonIcon={buttonIcon}
            />
          ) : (
            ""
          )}
        </div>
        <div className="right-side">
          <RenderContent content={content} />
        </div>
      </div>
    </section>
  );
};

TwoColumnTextAndCta.defaultProps = {
  boldText: "<p>This is the text content example in bold</p>",
  cta: {
    title: "Button Title",
    url: "https://mapmysay-staging.distl.dev/about/",
  },
  content: "<p>This is the text content example</p>",
  buttonTheme: "red",
  buttonIcon: "arrow-right",
};
