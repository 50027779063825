import React from "react";
import "./Header.scss";
import { decodeEntities } from "../../../utils/helpers";
import { Link as ScrollToLink } from "react-scroll";

export const Header = ({ header }) => {
  return (
    <section className="page-header">
      <div className="inner">
        <h1
          className="title"
          dangerouslySetInnerHTML={{ __html: decodeEntities(header) }}
        />
        <ScrollToLink
          to="homepage-two-col-text-and-cta"
          spy={true}
          smooth={true}
          duration={500}
          className="scroll-link"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
              stroke="#141C21"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 12L12 16L16 12"
              stroke="#141C21"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 8V16"
              stroke="#141C21"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </ScrollToLink>
      </div>
    </section>
  );
};
