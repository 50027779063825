import React from "react";
import { ImagePass } from "../../Image/ImagePass";
import RenderContent from "../../RenderContent";
import "./WideImageAndContent.scss";

export const WideImageAndContent = ({ image, content }) => {
  return (
    <section className="wide-image-and-content">
      <div className="inner">
        <div className="left-side">
          <div className="main-image-wrapper">
            <ImagePass src={image} lazy={false} />
          </div>
        </div>

        <div className="right-side">
          <RenderContent content={content} />
        </div>
      </div>
    </section>
  );
};

WideImageAndContent.defaultProps = {
  image: null,
  content: "<p>This is the text content example</p>",
};
