import React from "react";
import { ImagePass } from "../../Image/ImagePass";
import GatsbyLink from "../../GatsbyLink";
import RenderContent from "../../RenderContent";
import "./ProjectListAndImage.scss";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";

const ImageContent = ({ mainImage, iconTop, iconBottom }) => {
  return (
    <ParallaxProvider>
      <div className="image-part">
        <div className="main-image-wrapper">
          {iconTop && (
            <Parallax
              y={[-30, 30]}
              tagOuter="div"
              className="image-icon image-icon--top"
            >
              <ImagePass src={iconTop} lazy={false} />
            </Parallax>
          )}
          <ImagePass src={mainImage} className="main-image-img" />
          {iconBottom && (
            <Parallax
              y={[-30, 30]}
              tagOuter="div"
              className="image-icon image-icon--bottom"
            >
              <ImagePass src={iconBottom} lazy={false} />
            </Parallax>
          )}
        </div>
      </div>
    </ParallaxProvider>
  );
};

const TextContent = ({ title, content, projectLinks }) => {
  return (
    <div className="text-part">
      <RenderContent content={title} />

      <div className="content">{content}</div>
      {projectLinks &&
        projectLinks?.map((projectLink, index) => (
          <GatsbyLink
            to={projectLink.projectLink.url}
            className="text-part--cta"
            key={index}
          >
            {projectLink.projectLink.title}
          </GatsbyLink>
        ))}
    </div>
  );
};

export const ProjectListAndImage = ({
  title,
  content,
  projectLinks,
  mainImage,
  iconTop,
  iconBottom,
}) => {
  return (
    <section className="project-list-and-image-component">
      <div className="inner">
        <TextContent
          title={title}
          content={content}
          projectLinks={projectLinks}
        />
        <ImageContent
          mainImage={mainImage}
          iconTop={iconTop}
          iconBottom={iconBottom}
        />
      </div>
    </section>
  );
};

ProjectListAndImage.defaultProps = {
  title: "Default title",
  content: "Default content",

  projectLinks: [
    {
      title: "Default link 1",
      url: "/",
    },
    {
      title: "Default link 2",
      url: "/",
    },
  ],
  mainImage: "https://via.placeholder.com/430",
  iconTop: "https://via.placeholder.com/150/0000FF/808080",
  iconBottom: "https://via.placeholder.com/150/000000/FFFFFF",
};
