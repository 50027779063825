import React from "react";
import PropTypes from "prop-types";
import "./ThreeColumnCallout.scss";

export const ThreeColumnCallout = ({ columns }) => {
  return (
    <section className="three-column-callout-component">
      <div className="inner">
        {columns &&
          columns.map((column, index) => (
            <div className="column" key={index}>
              {column.icon && (
                <img
                  src={column?.icon?.source_url}
                  className="icon"
                  alt="icon"
                />
              )}
              <div className="title">{column?.title}</div>
              <div className="content">{column?.content}</div>
            </div>
          ))}
      </div>
    </section>
  );
};

ThreeColumnCallout.propTypes = {
  columns: PropTypes.array,
};

ThreeColumnCallout.defaultProps = {
  columns: [
    {
      icon: "https://via.placeholder.com/100/808080/000000",
      title: "Default title",
      content: "Default text content",
    },
  ],
};
