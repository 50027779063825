import React, { useState, useEffect } from "react";
import { ImagePass } from "../../Image/ImagePass";
import GatsbyLink from "../../GatsbyLink";
import "./ProjectPreviewTiles.scss";
import chunk from "lodash.chunk";

export const ProjectPreviewTiles = ({ rows }) => {
  const [chunkedRows, setRows] = useState();

  React.useEffect(() => {
    setRows(chunk(rows, 2));
  }, [rows]);

  if (chunkedRows === undefined) {
    return null;
  }

  const ContentType = ({ row }) => {
    switch (row.contentType) {
      case "image":
        return (
          <div className="image-part">
            <ImagePass src={row.imagePath} lazy={false} className="image-img" />
          </div>
        );
      case "text":
        return (
          <div className="text-part">
            <div className="text-part--inner">
              {row.cta && (
                <GatsbyLink
                  to={row.cta.url}
                  className="text-part--blue-title-cta"
                >
                  <h2 className="text-part--blue-title">{row.blueTitle}</h2>
                </GatsbyLink>
              )}

              <p className="text-part--content">{row.content}</p>
              {row.cta && (
                <GatsbyLink
                  to={row.cta.url}
                  className="text-part--cta"
                ></GatsbyLink>
              )}
            </div>
          </div>
        );

      default:
        return <p>This content type doesnt exist</p>;
    }
  };

  return (
    <section className="project-preview-tiles-component">
      <div className="wrapper">
        <div className="inner">
          {Object.keys(chunkedRows).length > 0 &&
            chunkedRows?.map((row, index) => {
              return (
                <div className="row" key={index}>
                  {Object.keys(row).length > 0 &&
                    row?.map((col, colIndex) => {
                      return <ContentType row={col} key={colIndex} />;
                    })}
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

ProjectPreviewTiles.defaultProps = {
  rows: [
    {
      contentType: "image",
      imagePath: "https://via.placeholder.com/430",
      blueTitle: null,
      content: null,
      cta: null,
    },
    {
      contentType: "text",
      imagePath: null,
      blueTitle: "<p>This is the blue title example</p>",
      content: "<p>This is the text content example</p>",
      cta: {
        url: "/",
      },
    },
  ],
};
