import React from "react";
import { Button } from "../../Button";
import GatsbyLink from "../../GatsbyLink";
import { ImagePass } from "../../Image/ImagePass";
import RenderContent from "../../RenderContent";
import "./Publications.scss";

export const Publications = ({ eyebrow, heading, publications }) => {
  return (
    <section className="publications-component">
      <div className="inner">
        <div className="heading-part">
          <div className="eyebrow">{eyebrow}</div>
          <div className="heading">{heading}</div>
        </div>

        <div className="publications-part">
          <div className="publications-inner">
            {publications &&
              publications?.map((publication, i) => (
                <div className="publication" key={i}>
                  <div className="publication--left-side">
                    <div className="image-wrapper">
                      <GatsbyLink
                        to={publication?.link?.url}
                        label={publication.title}
                      >
                        <ImagePass
                          src={publication.image}
                          alt="decorative image"
                          className="publication-image"
                        />
                      </GatsbyLink>
                    </div>
                  </div>
                  <div className="publication--right-side">
                    <GatsbyLink
                      to={publication?.link?.url}
                      label={publication.title}
                    >
                      <div className="title">{publication.title}</div>
                    </GatsbyLink>
                    <div className="bold-text">{publication.boldText}</div>
                    <RenderContent
                      className="content"
                      content={publication.content}
                    />
                    <div className="link-button">
                      {publication.link ? (
                        <Button
                          buttonLink={publication?.link?.url}
                          buttonText={publication?.link?.title}
                          buttonTheme="red"
                          buttonIcon="arrow-up-right"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

Publications.defaultProps = {
  eyebrow: "Default eyebrow text",
  heading: "Default heading",
  publications: [
    {
      image: {
        sourceUrl:
          "https://i.picsum.photos/id/866/552/308.jpg?hmac=2WfF0WhmLjTyO4Ij8NUPtuucwuaEdaW_f9bgdhRyoGQ",
      },
      title: "Default title example",
      boldText: "Default bold text example",
      content: "<p>This is the content example</p>",
      link: {
        title: "Default link title",
        url: "/",
      },
    },
    {
      image: {
        sourceUrl:
          "https://i.picsum.photos/id/1053/552/308.jpg?hmac=NDVBgt1DDhmV_xnPH7ABTcdefHMtLLBgyuPJZ0oFKRI",
      },
      title: "Default title example",
      boldText: "Default bold text example",
      content: "<p>This is the content example</p>",
      link: {
        title: "Default link title",
        url: "/",
      },
    },
  ],
};
