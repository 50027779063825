import React from "react";
import { GravityForm, gravityFormExtractor } from "../../gravity-form";
import { useAllGravityForms } from "../../../hooks";
import "./ContactBlock.scss";

export const ContactBlock = ({
  eyebrow,
  title,
  boldText,
  content,
  gravityFormId,
}) => {
  // Get all of our Gravity Forms
  const gravityFormsData = useAllGravityForms();

  // Retrieve our form and formfields
  const formId = parseInt(gravityFormId);
  const formData = gravityFormExtractor(formId, gravityFormsData);

  return (
    <section className="contact-block-component">
      <div className="inner">
        <div className="left-side">
          <p className="eyebrow">{eyebrow}</p>
          <h3 className="left-side--title">{title}</h3>
          <p className="left-side--bold-text">{boldText}</p>
          <p className="left-side--content">{content}</p>
        </div>
        <div className="right-side">
          {gravityFormsData && (
            <GravityForm
              formData={formData}
              dataLayerEvent={"form_submit_success"}
              displayTitle={false}
              successMessage={`Thank you for your message! We will get back to you as soon as possible.`}
            />
          )}
        </div>
      </div>
    </section>
  );
};

ContactBlock.defaultProps = {
  title: "This is the title example",
  boldText: "This is the bold text example",
  content: "<p>This is the text content example</p>",
};
