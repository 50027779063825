import React from "react";
import RenderContent from "../../RenderContent";
import "./ProjectHeaderAndSurveyCounter.scss";

export const ProjectHeaderAndSurveyCounter = ({
  eyebrows,
  heading,
  boldText,
  content,
  surveyRows,
}) => {
  return (
    <section className="project-header-and-survey-counter--component">
      <div className="inner">
        <div className="left-side">
          <div className="eyebrows-wrapper">
            {eyebrows &&
              eyebrows.map((eyebrow, index) => (
                <div className="eyebrow" key={index}>
                  {eyebrow.eyebrow}
                </div>
              ))}
          </div>
          <div className="heading">{heading}</div>
          <div className="bold-text">{boldText}</div>
          <RenderContent className="content" content={content} />
        </div>
        <div className="right-side">
          <div className="survey-counter-wrapper">
            {surveyRows &&
              surveyRows.map((row, i) => (
                <div className="row--wrapper" key={i}>
                  <div className="row--count">{row.count}</div>
                  <div className="row--text">{row.text}</div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

ProjectHeaderAndSurveyCounter.defaultProps = {
  eyebrows: [{ eyebrow: "Eyebrow" }],
  heading: "Default heading",
  boldText: "Default bold text",
  content: "<p>This is the text content example</p>",
  surveyRows: [
    {
      count: "20,000",
      text: "Default survey text",
    },
  ],
};
