import React from "react";
import { Link } from "gatsby";
import RenderContent from "../../RenderContent";
import "./WideTitleAndText.scss";

export const WideTitleAndText = ({ title, content }) => {
  return (
    <section className="wide-title-and-text-component">
      <div className="inner">
        <div className="left-side">
          <RenderContent content={title} />
        </div>
        <div className="right-side">
          <RenderContent content={content} />
        </div>
      </div>
    </section>
  );
};

WideTitleAndText.defaultProps = {
  heading: "<p>This is the title example</p>",
  content: "<p>This is the text content example</p>",
};
