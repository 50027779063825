import React from "react";
import PropTypes from "prop-types";
import { ImagePass } from "../../Image/ImagePass";
import RenderContent from "../../RenderContent";
import "./ProjectTwoColumnImageAndContent.scss";

const ContentType = ({ row }) => {
  switch (row.contentType) {
    case "image":
      return (
        <div className="image-part">
          <div className="image-wrapper">
            <ImagePass src={row.image} lazy={false} className="image-img" />
          </div>
        </div>
      );
    case "text":
      return (
        <div className="text-part">
          <div className="text-part--inner">
            <div className="title">{row.title}</div>
            <div className="bold-text">{row.boldText}</div>
            <RenderContent content={row.content} />
          </div>
        </div>
      );

    default:
      return <p>This content type doesnt exist</p>;
  }
};

export const ProjectTwoColumnImageAndContent = ({ heading, row }) => {
  return (
    <section className="project-two-column-image-and-content--component">
      <div className="inner">
        <div className="heading">{heading}</div>

        <div className="row--wrapper">
          {row &&
            row?.map((col, index) => <ContentType row={col} key={index} />)}
        </div>
      </div>
    </section>
  );
};

ProjectTwoColumnImageAndContent.propTypes = {
  heading: PropTypes.string,
  row: PropTypes.array,
};

ProjectTwoColumnImageAndContent.defaultProps = {
  heading: "This is the heading example",
  row: [
    {
      contentType: "image",
      image: "https://via.placeholder.com/430",
      title: "Default title example",
      boldText: "Default bold text example",
      content: null,
    },
  ],
};
