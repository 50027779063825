import React from "react";
import { ImagePass } from "../../Image/ImagePass";
import RenderContent from "../../RenderContent";
import "./ProjectImageAndContent.scss";

export const ProjectImageAndContent = ({ image, title, boldText, content }) => {
  return (
    <section className="project-image-and-content">
      <div className="inner">
        <div className="left-side">
          <div className="main-image-wrapper">
            <ImagePass src={image} lazy={false} />
          </div>
        </div>

        <div className="right-side">
          <div className="title">{title}</div>
          <div className="bold-text">{boldText}</div>
          <RenderContent content={content} />
        </div>
      </div>
    </section>
  );
};

ProjectImageAndContent.defaultProps = {
  image: null,
  title: "Default title",
  boldText: "Default bold text",
  content: "<p>This is the text content example</p>",
};
